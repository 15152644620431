<template>
  <div class="main">
    <Dialog ref="dialog" :config="config" />
    <div class="box">
      <div class="boxTitle">{{ $fanyi('提现申请信息') }}</div>
      <div class="userBalanceContainer">
        <div>
          {{ $fanyi('当前账户余额') }}：<span>${{ userInfo.balance_usable }}</span>
        </div>
      </div>
      <div class="boxBody topUp">
        <div class="rechargeBasicInformation">{{ $fanyi('基本信息') }}</div>
        <div class="flex label1" style="margin-top: 30px">
          <div>*</div>
          <div>{{ $fanyi('账户名称') }}：</div>
        </div>
        <el-input v-model="userName" :placeholder="$fanyi('请输入用户名')" />
        <div class="flex label2" style="margin-top: 30px">
          <div>*</div>
          <div>{{ $fanyi('银行账户') }}：</div>
        </div>
        <el-input v-model="bankAccount" :placeholder="$fanyi('请输入银行账户')" />
        <div class="flex label1" style="margin-top: 30px">
          <div>*</div>
          <div>{{ $fanyi('提现金额') }}：</div>
        </div>
        <el-input v-model="withdrawalPrice" @input="updateWithdrawalPrice" type="number"
          :placeholder="$fanyi('请输入提现金额')" />
        <div class="flex label2" style="margin-top: 30px">
          <div>*</div>
          <div>{{ $fanyi('提现申请原因') }}：</div>
        </div>
        <el-input v-model="bankRemark" :placeholder="$fanyi('请输入提现申请原因')" />
        <div class="btnContainer" @click="showWithdrawalConfirmDialogVisible">
          <div>{{ $fanyi('确认提交') }}</div>
        </div>
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible" width="400px" :show-close="false">
      <div class="flexAndCenter couponHeader">
        <div>{{ $fanyi('信息确认') }}</div>
        <i class="el-icon-close" @click="dialogVisible = false"></i>
      </div>
      <div class="couponBody">
        <div class="withdrawalSubmitText">
          {{ $fanyi('请您核对以下信息是否正确') }}
        </div>
        <div class="flexAndCenter withdrawalMessage">
          <div class="leftText">{{ $fanyi('提现金额') }}：</div>
          <div class="flexAndCenter withdrawalPrice">
            <div>{{ $fun.EURNumSegmentation(withdrawalPrice) }}</div>
            <div>{{ $fanyi('美元') }}</div>
          </div>
        </div>
        <div class="flexAndCenter withdrawalMessage">
          <div class="leftText">{{ $fanyi('账户名称') }}：</div>
          <div class="rightText">{{ userName }}</div>
        </div>
        <div class="flexAndCenter withdrawalMessage" style="margin-bottom: 0px">
          <div class="leftText">{{ $fanyi('银行账户') }}：</div>
          <div class="rightText">{{ bankAccount }}</div>
        </div>
      </div>
      <div class="btnList flexAndCenter">
        <div @click="Withdraw">{{ $fanyi('确认') }}</div>

        <div @click="dialogVisible = false">{{ $fanyi('取消') }}</div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Dialog from '../../components/public/Dialog.vue'
export default {
  data() {
    return {
      config: {
        top: '20vh',
        width: '500px',
        title: this.$fanyi('付款'),
        txtcenter: true,
        btnTxt: [this.$fanyi('提交'), this.$fanyi('取消')],
      },
      dialogVisible: false,
      userName: '',
      bankAccount: '',
      withdrawalPrice: '',
      bankRemark: '',
      userInfo: {},
      timer: '',

    }
  },
  components: { Dialog },
  computed: {},
  created() {
    this.$api.EuropegetUserInfo().then((res) => {
      this.$store.commit('userData', res.data)
      this.userInfo = res.data
    })
  },
  methods: {
    // 提现
    Withdraw() {
      this.dialogVisible = false
      if (!this.timer) {
        this.timer = setTimeout(() => {
          this.$api.capitalCashOutSend({
            client_name: this.userName,
            client_account: this.bankAccount,
            client_amount: this.withdrawalPrice,
            client_reason: this.bankRemark,
          }).then((res) => {

            if (res.code !== 0) return this.$message.error(res.msg)



            this.$fun.toPage('/user/churujinList')
            this.$message({
              message: '提现成功，待财务审核',
              type: 'success',
            })
            location.reload()
          })
          this.timer = null

        }, 1000)

      }



    },
    submitForm(formName) {
      if (!this.ruleForm.pay_image_url)
        return this.$message(this.$fanyi('请上传支付截图'))
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$refs.dialog.open(
            this.$fanyi('是否提交充值信息？'),
            () => {
              this.$api.capitalBankUpBalance(this.ruleForm).then((res) => {
                if (res.code != 0)
                  return this.$message.error(this.$fanyi('提交失败'))
                // 重新获取用户余额
                this.$api.EuropegetUserInfo().then((res) => {
                  this.$store.commit('userData', res.data)
                })
                  ; (this.ruleForm = {
                    bill_method: 2,
                    pay_realname: '',
                    pay_amount: '',
                    pay_remark: '',
                    pay_date: '',
                    pay_image_url: '',
                  }),
                    this.$message.success(this.$fanyi('提现成功'))
              })
            },
            () => { }
          )
        } else {

          return false
        }
      })
    },
    //校验提现金额是否合法
    updateWithdrawalPrice(e) {
      if (Number(e) > this.userInfo.balance) {
        this.withdrawalPrice = this.userInfo.balance
      }
      if (Number(e) < 0) {
        this.withdrawalPrice = ''
      }
      this.withdrawalPrice =
        /^\d+\.?\d{0,2}$/.test(Number(e)) || Number(e) == ''
          ? this.withdrawalPrice
          : (this.withdrawalPrice = '')
    },
    //提交提现申请
    showWithdrawalConfirmDialogVisible() {
      if (this.withdrawalPrice < 0 || this.withdrawalPrice == 0) {
        this.$message.error(this.$fanyi('提现金额必须大于0'))
        return
      }
      if (
        this.userName === '' ||
        this.bankAccount === '' ||
        this.withdrawalPrice === '' ||
        this.bankRemark === ''
      ) {
        this.$message.error(this.$fanyi('缺少必填项'))
      } else {
        this.dialogVisible = true
      }
    },
    setNum(e) {
      this.payInput = e.replace(/([1-9]+\.[0-9]{2})[0-9]*/, '$1')
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../../css/mixin.scss';

.main {
  width: 1060px;
  height: 832px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #e2e2e2;
  overflow: hidden;

  /deep/ .el-dialog__header {
    display: none;

  }

  /deep/.el-dialog__body {
    padding: 0 !important;
  }

  /deep/ .el-dialog__header,
  /deep/ .el-dialog__body,
  /deep/.el-dialog__footer {
    padding: 0;
  }

  .box {
    background: #ffffff;

    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }

    .boxTitle {
      width: 1060px;
      height: 60px;
      background: #f2f2f2;

      display: flex;
      align-items: center;
      padding: 30px;
      font-weight: 500;
      font-size: 18px;
    }

    .userBalanceContainer {
      width: 1000px;
      height: 88px;
      background: #f6f6f6;
      border-radius: 6px;
      margin: 30px 30px 30px 40px;
      padding-left: 30px;
      background: #f9f9f9;
      display: flex;

      align-items: center !important;

      div {
        font-size: 14px;
        font-family: Roboto-Regular, Roboto;
        font-weight: 400;
        color: #222222;
        display: flex;

        align-items: center;

        span {
          font-size: 32px;
          font-family: Roboto-Black, Roboto;
          font-weight: 900;
          color: #ff730b;
        }
      }
    }

    .topUp {
      width: 100%;
      padding: 0 60px 40px;

      .rechargeBasicInformation {
        color: #000000;
        font-weight: 500;
        font-size: 16px;
        margin-bottom: 30px;
      }

      .label1,
      .label2 {
        font-size: 14px;
        margin-bottom: 10px;

        div:first-child {
          color: #e3372f;
          margin-right: 5px;
        }

        div:last-child {
          color: #222222;
        }
      }

      .bankListContainer {
        .selectBankContainer {
          background: #fff1e6;
          border: 1px solid #ffbe8e;
        }

        div {
          width: 230px;
          height: 64px;
          background: #f9f9f9;
          border: 1px solid #e2e2e2;
          margin-right: 20px;
          justify-content: center;

          img {
            width: 156px;
            height: 33px;
          }
        }
      }

      /deep/ .el-input {
        .el-input__inner {
          width: 480px;
          height: 48px;
          background: #ffffff;
          border-radius: 6px;
          border: 1px solid #e2e2e2;
        }
      }

      .btnContainer {
        width: 160px;
        height: 48px;
        background: #ff730b;
        border-radius: 4px !important;
        text-align: center;
        margin-top: 30px;

        div {
          width: 160px;
          height: 48px;
          background: #ff730b;
          border-radius: 4px !important;
          text-align: center;
          margin-top: 30px;
          line-height: 48px;
          cursor: pointer;
          color: #fff;
        }
      }
    }
  }

  .couponHeader {
    justify-content: space-between;
    //padding:0 10px;
    border-bottom: 1px solid #f5f5f5;
    height: 64px;
    margin: 0 10px;

    div:first-child {
      color: #222222;
      font-size: 18px;
      padding-left: 20px;
    }

    .el-icon-close {
      color: #000;
      font-size: 28px;
      font-weight: bold;
      margin-right: 20px;
      cursor: pointer;
    }
  }

  .couponBody {
    padding: 30px 40px;

    .withdrawalSubmitText {
      margin-bottom: 24px;
      color: #999999;
      font-size: 14px;
    }

    .withdrawalMessage {
      margin-bottom: 20px;

      .leftText {
        color: #222222;
        font-size: 14px;
        min-width: 100px;
        text-align: right;
      }

      .withdrawalPrice {
        div:first-child {
          color: #ff730b;
          font-weight: 600;
          font-size: 18px;
          margin-right: 10px;
        }
      }
    }
  }

  .btnList {
    padding: 0 60px 30px;

    div {
      width: 120px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      border-radius: 4px;
      color: #fff;
      background: #ff730b;
      cursor: pointer;
      border: 1px solid #ff730b;
    }

    div:first-child {
      margin-right: 40px;
    }

    div:last-child {
      color: #222222;
      background: #fff;
      border: 1px solid #e2e2e2;
    }
  }
}

.el-range-separator {
  width: 50px;
}
</style>
